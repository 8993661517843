
import Vue from "vue";
// import headers from "../config/headers.json";
import API from "@/api/API";
import store from "@/store";
import ImageViewer from "@/components/viewers/Image.vue";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";
import { Dictionary } from "vue-router/types/router";
import { DataTableHeader } from "vuetify";

export default Vue.extend({
  name: "Roles",

  components: { ImageViewer },

  mixins: [tablePagination, tableFilters],

  data: () => ({
    headers: [] as Array<DataTableHeader>,
    parseArrayParams,
    items: [] as Array<any>,
    filter: {
      quick_filter: ""
    } as Dictionary<string | (string | null)[]>,
    showSearch: false as boolean,
    loading: false as boolean,
    queryString: "" as string,
    selects: {
      services: [] as Array<SelectComponentInterface>,
      centers: [] as Array<SelectComponentInterface>
    },
    showMenu: false as boolean,
    x: 0,
    y: 0
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 48
        }
      };
      const response = await API.roles().getData(params);

      next(vm => {
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.roles().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });
        this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setServerResponse({ items, headers }: any) {
      this.items = items.data;
      this.headers = [
        ...headers,
        {
          text: "",
          value: "actions",
          align: "center",
          width: "140px",
          sortable: false
        }
      ];
      this.totalItems = items.total;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
        this.showSearch = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    toggleSearch(): void {
      this.showSearch = !this.showSearch;

      if (this.showSearch) {
        this.$nextTick(() => {
          (this.$refs.search as any).focus();
        });
      }
    },
    search() {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.applyFilters);
      this.debounced();
    },
    async deleteRow(id: number): Promise<any> {
      try {
        await this.$API.roles().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    showContextMenu(e: any, item: any) {
      this.activeItemId = item.item.id;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    }
  }
});
